import type { FC } from 'react';
import React from 'react';
import { useTranslation } from '@lt/localization';
import { HeaderMenuCategory } from '@lt/components/components/HeaderMenu';
import HeaderCashbackComponent from './components/HeaderCashbackComponent';
import styles from './HeaderCashback.module.css';

export interface IProps {
  bonusScore: number;
  cashbackIconWebp: string;
  cashbackIconPng: string;
}

const HeaderCashback: FC<IProps> = (props) => {
  const { bonusScore, cashbackIconWebp, cashbackIconPng } = props;
  const { t } = useTranslation();

  const IconComponent = (
    <HeaderCashbackComponent
      bonusScore={bonusScore}
      cashbackIconWebp={cashbackIconWebp}
      cashbackIconPng={cashbackIconPng}
    />
  );

  return (
    <HeaderMenuCategory
      slug="cashback"
      className={`${styles.category} ${styles.noHover}`}
      data-testid="HEADER_CASHBACK_TEST_ID"
      IconComponent={IconComponent}
      name={t('cashback.basic.label')}
      isCompact={false}
    />
  );
};

export default HeaderCashback;
