import React from 'react';
import { Image } from '@lt/components';
import styles from './HeaderCashbackComponent.module.css';

const HeaderCashbackComponent = ({
  bonusScore,
  cashbackIconWebp,
  cashbackIconPng,
}) => (
  <div className={styles.infoWrapper}>
    <p>{bonusScore}</p>
    <Image
      alt="cashback icon"
      src={cashbackIconWebp}
      fallback={cashbackIconPng}
      className={styles.icon}
    />
  </div>
);

export default HeaderCashbackComponent;
