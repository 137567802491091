import type { SagaReturnType } from 'redux-saga/effects';
import { takeLatest, select, put } from 'redux-saga/effects';

import { api } from 'src/api';
import { getIsWhitelabel } from '../view/selectors';
import { INIT_APP } from '../view/constants';
import { setCashbackData } from './slice';

function* fetchCashbackData() {
  try {
    const isWhiteLabel: SagaReturnType<typeof getIsWhitelabel> = yield select(
      getIsWhitelabel,
    );

    let data = {};

    if (!isWhiteLabel) {
      data = window.cashback;
    } else {
      data = yield api.getPartnerSettings();
    }
    yield put(setCashbackData(data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Fetch cashback data error', e);
  }
}

export default function* cashbackSaga() {
  yield takeLatest(INIT_APP, fetchCashbackData);
}
